<template>
  <!-- Sub -->
  <div class="sub" :class="{ selected: selected, opened: opened }">

    <!-- Key Events -->
    <template v-if="(selected && !$root.sidebar && !$root.dialog)">
      <Keypress key-event="keydown" v-if="opened" :key-code="$root.keys.key_back" @success="closeSubMenu" />
      <Keypress key-event="keydown" :key-code="$root.keys.key_enter" @success="onEnter" />
      <Keypress key-event="keydown" :key-code="$root.keys.key_up" @success="changeMenu(-1)" />
      <Keypress key-event="keydown" :key-code="$root.keys.key_down" @success="changeMenu(1)" />
    </template>

    <div class="icon-container">
      <div class="icon">
        <img :src="sub.icon" />
        <div class="alt-icon" v-if="sub.altIcon">
          <img :src="sub.altIcon" />
        </div>
      </div>
      <p class="title" v-if="!opened">
        {{ sub.title }}
      </p>
    </div>

    <!-- Sub del sub -->
    <transition name="slide">
      <div
        class="list-menu"
        v-if="opened"
        :style="`transform:translateY(-${48 * listMenuData.selectedListMenuIndex}px)`"
      >
        <ListMenuItem
          v-for="(sub, i) in sub.subs"
          :sub="sub"
          :key="i"
          :parentIndex="index"
          :index="i"
          :selected="opened && listMenuData.selectedListMenuIndex === i"
        />
      </div>
    </transition>
  </div>
</template>
<script>
import ListMenuItem from "./ListMenuItem.vue";
export default {
  props: ["sub", "selected", "parentIndex", "index"],

  components: {
    ListMenuItem,
    Keypress: () => import('vue-keypress')
  },

  mounted () {
    if(this.opened) {
      this.listMenuData = this.$root.navState.listMenu
    }
    this.$root.$on('joypad_vertical', this.changeMenu)
    this.$root.$on('joypad_button', this.checkJoypadButton)
  },

  data() {
    return {
      listMenuData: {
        parentIndex: this.parentIndex,
        index: this.index,
        selectedListMenuIndex: this.opened ? this.$root.navState.listMenu.selectedListMenuIndex : 0,
      }
    };
  },

  computed: {
    opened () {
      return this.$root.navState.listMenu && this.$root.navState.listMenu.index === this.index
    }
  },

  beforeDestroy() {
    this.$root.$off('joypad_vertical', this.changeMenu)
    this.$root.$off('joypad_button', this.checkJoypadButton)
  },



  methods: {
    onEnter(){
      if(this.sub.func)this.sub.func(this.$root);
      else if(this.sub.subs)this.openSubMenu();
    },
    update() {
      this.$root.navState.listMenu = this.listMenuData;
    },
    checkJoypadButton(button){
      if(button == 'cross') this.openSubMenu()
      else if(button == 'circle') this.closeSubMenu()
    },
    openSubMenu() {
      if(this.opened || !this.selected || !this.sub.subs) return;
      this.update();
      this.$root.playSound("nav_forward");
    },

    closeSubMenu() {
      if(!this.opened || !this.selected) return
      this.$root.navState.listMenu = null;
      this.$root.playSound("nav_backward");
    },
    changeMenu(i) {
      if(!this.selected) return;
      if (i === -1) {
        if (this.listMenuData.selectedListMenuIndex == 0) return;
        this.listMenuData.selectedListMenuIndex--;
        this.$root.playSound("nav_forward");
      } else if(this.sub.subs) {
        if (this.listMenuData.selectedListMenuIndex == this.sub.subs.length - 1) return;
        this.listMenuData.selectedListMenuIndex++;
        this.$root.playSound("nav_forward");
      }
      this.update()
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-container {
  .icon {
    width: 48px;
    height: 48px;
    opacity: 0.5;
    transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    margin: 0 auto;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .title {
    opacity: 0;
    transition: 0.2s;
  }
}

.sub {
  margin: 1em auto;
  .icon-container {
    display: flex;
    position: relative;
    left: 50%;
    margin-left: -24px;
    .icon {
      margin: 0;
      background-color: transparent;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
      .alt-icon {
        position: absolute;
        left: -10px;
        top: -4px;
        img {
          width: 28px;
          height: 28px;
          object-fit: contain;
        }
      }
    }
    .title {
      margin-left: 1em;
      opacity: 0;
      transition: 0.2s;
      position: absolute;
      white-space: nowrap;
      bottom: 0;
      left: 50px;
    }
  }

  &.selected {
    color: white;
    &:not(.opened) {
      animation: active-glow 0.6s infinite linear alternate;
    }
    .title {
      opacity: 1;
    }
    .icon {
      opacity: 0.8;
    }
    .icon-container {
      filter: drop-shadow(5px 3px 5px rgba(0, 0, 0, 0.5));
    }
  }
}

.list-menu {
  position: absolute;
  top: 0;
  right: -80px;
  transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

@keyframes active-glow {
  from {
    filter: drop-shadow(none);
  }
  to {
    filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 1));
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.slide-enter, .slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(-1em);
}
</style>
