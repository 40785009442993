<template>
  <div class="wrapper" :class="{ sidebarOpened: $root.sidebar, dialogOpened: $root.dialog, overlay: dialog.type == 'alert' || dialog.type == 'input' }">

    <!-- Keyboard -->
    <Keypress v-if="dialog.guide.circle && dialog.guide.circle.func" key-event="keydown" :key-code="$root.keys.key_back" @success="() => {dialog.guide.circle.func($root, $router);$root.playSound('nav_backward')}"  />
    <Keypress v-if="(dialog.guide.cross && dialog.guide.cross.func) || dialog.actions" key-event="keydown" :key-code="$root.keys.key_enter" @success="enter"/>

    <template v-if="dialog.actions">
      <Keypress key-event="keydown" :key-code="$root.keys.key_left" @success="changeSelect(-1)" />
      <Keypress key-event="keydown" :key-code="$root.keys.key_right" @success="changeSelect(1)" />
    </template>

    <div class="dialog-content">
      {{dialog.content}}


      <div class="action-bar" v-if="dialog.actions">

        <div class="action" v-for="(action,i) in dialog.actions" :key="'action-'+i" :class="{selected: selectedAction == i}">
          {{action.label}}
        </div>

      </div>


    </div>

    <div class="guide-bar" v-if="dialog.guide">

      <div class="guide" v-for="(g,i) in dialog.guide" :key="i" id="back">
        <i :class="'ps-'+i"></i> <span>{{g.label}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Keypress from 'vue-keypress';
export default {

  props: [
    'dialog'
  ],

  data() {
    return {
      selectedAction: 0
    }
  },

  components: {
    Keypress,
  },

  mounted() {
    if(this.dialog.type === 'alert') this.$root.playSound('alert')
    else if(this.dialog.type === 'input') this.$root.playSound('input')
  },

  methods: {

    changeSelect(i) {

      if(i === 1 && this.selectedAction < this.dialog.actions.length) this.selectedAction++
      else if(i === -1 && this.selectedAction > 0) this.selectedAction--

    },

    enter() {
      if(this.dialog.actions && this.dialog.actions[this.selectedAction].func) {
        this.dialog.actions[this.selectedAction].func(this.$root, this.$router)
      } else {
        this.dialog.guide.cross.func(this.$root, this.$router);
      }
      this.$root.playSound('nav_forward')
    }

  }


};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  &.overlay {
    text-align: center;
    position: absolute;
    top:0;
    left: 0;
    width:100%;
    height: 100%;
    background-color: rgba(165, 165, 165, 0.733);
  }

  .dialog-content {
    position: relative;
    padding: 2em 3em;
    box-sizing: border-box;
    text-shadow: 5px 3px 5px rgba(0, 0, 0, 0.5);
    margin: 0 auto;
    width: 90vw;
    max-width: 600px;
    opacity: 0;
    animation: opacity .6s forwards cubic-bezier(0.19, 1, 0.22, 1);
    // animation-delay: .3s;
    &:before,
    &:after {
      content: " ";
      width: 100%;
      height: 1px;
      background-color: white;
      position: absolute;
      left: 0;
      box-shadow:  5px 3px 5px rgba(0, 0, 0, 0.5);
    }

    &:before {
      top:0;
      animation: bottomSlide .4s forwards cubic-bezier(0.19, 1, 0.22, 1);
      // animation-delay: .3s;
    }

    &:after {
      bottom:0;
      animation: topSlide .4s forwards cubic-bezier(0.19, 1, 0.22, 1);
      // animation-delay: .3s;
    }

  }

  .action-bar {
    display: flex;
    justify-content: center;
    margin-top: .5em;
    .action{
      padding: 0 .5em;
      margin: .5em;
      text-align: center;
      &.selected {
        animation: flash .5s infinite alternate-reverse linear;
      }
    }
  }

  .guide-bar {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    width: 100%;
    height: 2.5em;
    filter: drop-shadow(5px 3px 5px rgba(0, 0, 0, 0.5));
    .guide {
      margin: 0 1.5em;
      span {
        margin-left: 0.3em;
      }
    }
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
};

@keyframes bottomSlide {
  from {
    transform: translatey(2em);
  }
  to {
    transform: translatey(0);
  }
}

@keyframes topSlide {
  from {
    transform: translatey(-2em);
  }
  to {
    transform: translatey(0);
  }
}

@keyframes flash {
  from {
    background-color: #FFF0;
  }
  to {
    background-color: #FFF5;
  }
}



</style>

