<template>
  <div class="wrapper">
    <XMBMenu


      v-gamepad:button-dpad-up.repeat="() => $root.$emit('joypad_vertical', -1)"
      v-gamepad:button-dpad-down.repeat="() => $root.$emit('joypad_vertical', 1)"
      v-gamepad:left-analog-up.repeat="() => $root.$emit('joypad_vertical', -1)"
      v-gamepad:left-analog-down.repeat="() => $root.$emit('joypad_vertical', 1)"

      v-gamepad:button-dpad-left.repeat="() => $root.$emit('joypad_horizontal', -1)"
      v-gamepad:button-dpad-right.repeat="() => $root.$emit('joypad_horizontal', 1)"
      v-gamepad:left-analog-left.repeat="() => $root.$emit('joypad_horizontal', -1)"
      v-gamepad:left-analog-right.repeat="() => $root.$emit('joypad_horizontal', 1)"

      v-gamepad:button-a="() => $root.$emit('joypad_button', 'cross')"
      v-gamepad:button-b="() => $root.$emit('joypad_button', 'circle')"
      v-gamepad:button-x="() => $root.$emit('joypad_button', 'square')"
      v-gamepad:button-y="() => $root.$emit('joypad_button', 'triangle')"


    />
     <transition name="sidebar">
      <XMBSidebar v-if="$root.sidebar" :sidebar="$root.sidebar" />
     </transition>
  </div>
</template>

<script>
import XMBMenu from "../components/XMBMenu.vue";
import XMBSidebar from "../components/XMBSidebar.vue";
// import GameBoot from './components/GameBoot.vue'

export default {
  name: "App",
  components: {
    XMBMenu,
    XMBSidebar,
    //GameBoot
  },
};
</script>

<style>
.wrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.sidebar-enter-active, .sidebar-leave-active {
  transition: .5s cubic-bezier(0.19, 1, 0.22, 1);
}
.sidebar-enter, .sidebar-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(20%);
}
</style>
