<template>

  <div class="loading">

    <div class="bar" :class="{loop:loop}">
      <div id="completion" :style="`width:${loop ? 50 : (percentage || 0)}%`"></div>
    </div>

     <p id="label" v-if="!hideLabel && !loop">{{percentage ? percentage : 0}}%</p>


  </div>

</template>
<script>
export default {

  props: [
    'percentage',
    'hideLabel',
    'loop'
  ]

}
</script>
<style lang="scss" scoped>

  .loading {
    text-align: center;
    margin: 1.5em auto;
    .bar {
      width:100%;
      height: 5px;
      box-shadow: 5px 3px 5px rgba(0, 0, 0, 0.3);
      background-color: white;
      position: relative;
      overflow: hidden;
      #completion {
        position: absolute;
        top:0;
        left: 0;
        height: 100%;
        background-color: #01094D;
        background-image: linear-gradient(to top, #1c3ed1, #3459ff);
        transition: .5s cubic-bezier(0.19, 1, 0.22, 1);
      }

      &.loop {
        background-color: transparent;
        box-shadow: 5px 3px 5px rgba(0, 0, 0, 0.1);
        height: 3px;
        #completion {
          animation: 1s loop forwards infinite;
          background-color: transparent;
          background-image: linear-gradient(to right, #FFF0, #FFFA, #FFF0);
        }
      }

    }
    #label {
        margin-top: .5em;
    }
  }

@keyframes loop {
  0% {
    transform: translateX(-50%);
  }
  50% {
    transform: translateX(150%);
  }
  100% {
    transform: translateX(-50%);
  }
}

</style>
