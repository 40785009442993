<template>
  <div
    class="menu"
    :class="{listMenuOpened: $root.navState.listMenu !== null}"
    :style="
      `transform:translateX(calc(-${4 * $root.navState.currentMenu}em - ${48 *
        $root.navState.currentMenu}px))`
    "
  >

    <!-- Key Events -->
    <Keypress key-event="keydown" :key-code="$root.keys.key_left" @success="changeMenu(-1)" />
    <Keypress key-event="keydown" :key-code="$root.keys.key_right" @success="changeMenu(1)" />

    <MenuItem
      v-for="(menu, i) in $root.menu"
      :menuIndex="i"
      :class="{
        subMenuHere:
          $root.navState.listMenu && $root.navState.listMenu.parentIndex === i,
      }"
      :selected="$root.navState.currentMenu == i"
      :menu="menu"
      :key="'menu-' + i"
    />
  </div>
</template>
<script>
import MenuItem from "./menu/MenuItem.vue";
export default {
  components: {
    MenuItem,
    Keypress: () => import('vue-keypress')
  },



  mounted () {
    this.$root.$on('joypad_horizontal', this.changeMenu)
  },

  beforeDestroy() {
    this.$root.$off('joypad_horizontal', this.changeMenu)
  },

  methods: {
    changeMenu(i) {
      if (i == -1) {
        this.currentSubindex = 0;
        if (this.$root.navState.currentMenu === 0 || this.$root.navState.listMenu !== null) return;
        else this.$root.navState.currentMenu--;
        this.$root.playSound('nav_forward');
      } else {
        this.currentSubindex = 0;
        if (
          this.$root.navState.currentMenu === this.$root.menu.length - 1 ||
          this.$root.navState.listMenu !== null
        )
          return;
        else this.$root.navState.currentMenu++;
        this.$root.playSound('nav_forward');
      }
    },
  },

  data() {
    return {
      currentSubindex: 0,
    };
  },
};
</script>
<style lang="scss" scoped>
.menu {
  display: flex;
  color: rgba(255, 255, 255, 0.5);
  transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);

  position: absolute;
  top: 30%;
  left: 10%;
  opacity: 0;
  animation: 0.5s fade forwards;
}

.listMenuOpened {
  & > *:not(.subMenuHere) {
    opacity: 0;
    visibility: hidden;
  }
  .subMenuHere {
    transform: translateX(-100%);
  }
}

.boot .menu {
  animation-delay: 5s;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
