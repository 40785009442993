<template>
  <div class="list-menu-item" :class="{ selected: selected }" >

    <!-- Key Events -->
    <Keypress v-if="selected && !$root.sidebar && !$root.dialog" key-event="keydown" :key-code="$root.keys.key_enter" @success="open" />

    <div class="icon-container">
      <div class="icon">
        <img :src="sub.icon" />
        <div class="alt-icon" v-if="sub.altIcon">
          <img :src="sub.altIcon" />
        </div>
      </div>
      <p class="title">
        {{ sub.title }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: ["sub", "selected", "parentIndex", "index"],

  data() {
    return {
      opened: false,
    };
  },

  mounted () {
    this.$root.$on('joypad_button', this.checkJoypadButton)
  },

  components: {
    Keypress: () => import('vue-keypress')
  },

  beforeDestroy() {
    this.$root.$off('joypad_button', this.checkJoypadButton)
  },


  methods: {
    checkJoypadButton(button) {
      if(!this.selected) return;
      if(button == 'cross') this.open()
    },
    open() {
      if (this.sub.sidebar) {
        this.$root.sidebar = this.sub.sidebar;
        this.$root.playSound("nav_forward");
      } else if (this.sub.url) {
        this.$router.push(this.sub.url);
        if(!this.sub.disableNavSound) this.$root.playSound("nav_forward");
      } else if(this.sub.func) {
        this.sub.func(this.$root, this.$router);
        if(!this.sub.disableNavSound) this.$root.playSound("nav_forward");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-container {
  .icon {
    width: 48px;
    height: 48px;
    opacity: 0.5;
    transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    margin: 0 auto;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .title {
    opacity: 0;
    transition: 0.2s;
  }
}

.list-menu-item {
  .icon-container {
    display: flex;
    position: relative;
    left: 50%;
    margin-left: -24px;
    .icon {
      margin: 0;
      background-color: transparent;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
      .alt-icon {
        position: absolute;
        left: -10px;
        top: -4px;
        img {
          width: 28px;
          height: 28px;
          object-fit: contain;
        }
      }
    }
    .title {
      margin-left: 1em;
      opacity: 0.5;
      transition: 0.2s cubic-bezier(0.19, 1, 0.22, 1);
      position: absolute;
      white-space: nowrap;
      bottom: 0;
      left: 50px;
      margin-bottom: calc(1em - 4px);
      padding-bottom: 4px;
      border-bottom: 1px solid;
      border-color: transparent;
      width: 75vw;
      text-align: left;
    }
  }

  &.selected {
    color: white;
    animation: active-glow 0.6s infinite linear alternate;
    .title {
      opacity: 1;
      border-color: white;
    }
    .icon {
      opacity: 0.8;
    }
    .icon-container {
      filter: drop-shadow(5px 3px 5px rgba(0, 0, 0, 0.5));
    }
  }
}

.sidebarOpened .list-menu-item.selected {
 animation: none;
}

@keyframes active-glow {
  from {
    filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 1));
  }
  to {
    filter: drop-shadow(none);
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.slide-enter, .slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(-1em);
}
</style>
