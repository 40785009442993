<template>
  <div class="menu-item" :class="{ selected: selected }">

    <!-- Key Events -->
    <template v-if="selected">
      <Keypress key-event="keydown" :key-code="$root.keys.key_up" @success="changeMenu(-1)" />
      <Keypress key-event="keydown" :key-code="$root.keys.key_down" @success="changeMenu(1)" />
    </template>

    <div class="icon-container">
      <div class="icon">
        <img :src="menu.icon" />
      </div>
      <p class="title">{{ menu.title }}</p>
    </div>
    <div
      class="subs"

      v-if="menu.subs && menu.subs.length > 0"
      :style="
        `transform:translateY(calc(-${1 * currentSubIndex}em - ${48 *
          currentSubIndex}px))`
      "
    >
      <SubMenu
        :sub="sub"
        :parentIndex="menuIndex"
        :index="i"
        v-for="(sub, i) in menu.subs"
        :selected="selected && currentSubIndex === i"
        :style="`transform:translateY(calc(-${currentSubIndex> i ? 6 : 0}em))`"
        :key="'sub-' + i"
      />
    </div>
  </div>
</template>

<script>
import SubMenu from "./SubMenuItem";

export default {
  props: ["menu", "selected", "menuIndex"],

  components: {
    SubMenu,
    Keypress: () => import('vue-keypress')
  },

  mounted () {
    this.$root.$on('joypad_vertical', this.checkJoypadVertical)
  },

  computed: {
    currentSubIndex() {
      return this.$root.navState.menus[this.menuIndex]
    }
  },

  beforeDestroy() {
    this.$root.$off('joypad_vertical', this.checkJoypadVertical)
  },

  methods: {
    checkJoypadVertical(index) {
      this.changeMenu(index);
    },
    changeMenu(i) {
      if(!this.selected) return;
      if (i === -1) {
        // Up
        if (this.$root.navState.menus[this.menuIndex] === 0 || this.$root.navState.listMenu !== null) return;
        else this.$root.navState.menus[this.menuIndex]--;

      } else {
        // Down
        if (
          this.$root.navState.menus[this.menuIndex] === this.menu.subs.length - 1 ||
          this.$root.navState.listMenu !== null
        )
          return;
        else this.$root.navState.menus[this.menuIndex]++;

      }
      this.$root.playSound('nav_forward');
    },
  },
};
</script>
<style lang="scss" scoped>
.menu-item {
  margin: 0 2em;
  text-align: center;
  transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);

  .icon-container {
    margin-bottom:3em;
    position: relative;
    .icon {
      width: 48px;
      height: 48px;
      opacity: 0.7 ;
      transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
      margin: 0 auto;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
    .title {
      opacity: 0;
      transition: opacity 0.2s;
      position: absolute;
      left: 50%;
      transform: translatex(-50%);
      margin: 0;
    }
  }

  .subs {
    color: rgba(255, 255, 255, 0.5);
    opacity: 0;
    visibility: hidden;
  }

  &.selected {
    color: white;
    & > .icon-container {
      filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
      .icon {
        transform: scale(1.1);
        opacity: 0.8;
      }
      .title {
        opacity: 1;
      }
    }
    .subs {
      transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
      opacity: 1;
      visibility: visible;
    }
  }
}
</style>
