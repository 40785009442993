<template>
  <div class="welcome-screen">

    <div id="gradient-bg"></div>
    <div id="black-bg"></div>

    <div class="center-row">
      <p id="title-text">Sony Computer Entertainment</p>
    </div>

    <div class="anim-container">
      <div class="lines">
        <div id="line" style="top:0"></div>
        <div id="line" style="top:20%; animation-delay: .3s;"></div>
        <div id="line" style="top:30%; animation-delay: .4s;"></div>
        <div id="line" style="top:95%; animation-delay: .5s"></div>
        <div id="line" style="bottom:0"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted () {
    this.$root.playSound('boot')
  }
}
</script>
<style lang="scss" scoped>
#black-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  animation: fade 2.4s reverse forwards;
  animation-delay: .6s;
}
#gradient-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.7),
    black 30%,
    black 43%,
    rgba(0, 0, 0, 0.9) 48%,
    rgba(0, 0, 0, 0.9) calc(48% + 1em),
    black 55%
  );
  animation: fade .3s reverse forwards;
  animation-delay: 3.2s;
}

.welcome-screen {
  // background-color: rgba(0,0,0,1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  color: white;
  font-size: 16px;
  // animation: back .5s forwards;
  pointer-events: none;
  animation-delay: 3.3s;
}

#title-text,
.lines {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 1em;
  margin: 0;
}

.lines {
  animation: fade 1s reverse forwards;
  pointer-events: none;
  animation-delay: 2.4s;
}

.lines {
  width: 100%;
  height: 1.2em;
}

.anim-container {
  position: absolute;
  top: 50%;
  width: 100%;
  left: 0;
  opacity: 0;
  animation: 2s fade forwards;
}

#line {
  position: absolute;
  width: 200vw;
  height: 0.5px;
  background: linear-gradient(
    90deg,
    transparent 0%,
    rgba(255, 255, 255, 0.4) 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.4) 75%,
    transparent 100%
  );
  box-shadow: 0px 2px 5px rgba(255, 255, 255, 0.5);
  animation: 2s welcome-scroll infinite;
}

#title-text {
  opacity: 0;
  animation: fadeInOut 3s forwards;
  animation-delay: 0.8s;
}

@keyframes welcome-scroll {
  0% {
    transform: translateX(-100vw);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes back {
  0% {
    background-color: rgba(0, 0, 0, 1);
  }
  100% {
    background-color: rgba(0, 0, 0, 0);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
