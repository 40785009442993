<template>
  <div class="wrapper" :class="{ sidebarOpened: $root.sidebar, dialogOpened: $root.dialog}">

    <!-- Keyboard -->
    <Keypress  key-event="keydown" :key-code="$root.keys.key_back" @success="() => {$router.go(-1);$root.playSound('nav_backward')}"  />



    <div class="dialog-content">

      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin pulvinar tellus sit amet metus dapibus, eu consequat erat consectetur. Proin facilisis est nec magna pulvinar auctor. Nullam tincidunt nisi nibh, eu pharetra tellus pulvinar ut. Aliquam gravida nulla est, eget cursus mauris auctor in. Sed sed tortor in elit egestas sollicitudin ut non diam. Curabitur maximus, mi eu iaculis venenatis, augue felis facilisis odio, sagittis sollicitudin felis urna a nunc. Phasellus ac imperdiet metus. In ac erat fermentum, hendrerit turpis scelerisque, feugiat elit. Morbi vel dapibus ex. Donec vel augue mi.

      <LoadingBar :percentage="36" />

      <LoadingBar :loop="true" />



    </div>

    <div class="guide-bar" v-if="guide">

      <div class="guide" v-for="(g,i) in guide" :key="i" id="back">
        <i :class="'ps-'+i"></i> <span>{{g.label}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Keypress from 'vue-keypress';
import LoadingBar from '../components/LoadingBar'
export default {

  props: [
    'dialog'
  ],

  data () {
    return {
      guide: {
        circle: {
          label: 'Indietro'
        }
      }
    }
  },



  components: {
    Keypress,
    LoadingBar
  },



};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  &.overlay {
    text-align: center;
    position: absolute;
    top:0;
    left: 0;
    width:100%;
    height: 100%;
    background-color: rgba(165, 165, 165, 0.733);
  }

  .dialog-content {
    position: relative;
    padding: 2em 3em;
    box-sizing: border-box;
    text-shadow: 5px 3px 5px rgba(0, 0, 0, 0.5);
    margin: 0 auto;
    width: 90vw;
    max-width: 800px;
    opacity: 0;
    animation: opacity .3s forwards cubic-bezier(0.19, 1, 0.22, 1);
    animation-delay: .3s;
  /*  &:before,
    &:after {
      content: " ";
      width: 100%;
      height: 1px;
      background-color: white;
      position: absolute;
      left: 0;
      box-shadow:  5px 3px 5px rgba(0, 0, 0, 0.5);
    }


    &:before {
      top:0;
      animation: bottomSlide .6s forwards cubic-bezier(0.19, 1, 0.22, 1);
      animation-delay: .3s;
    }

    &:after {
      bottom:0;
      animation: topSlide .6s forwards cubic-bezier(0.19, 1, 0.22, 1);
      animation-delay: .3s;
    } */

  }

  .action-bar {
    display: flex;
    justify-content: center;
    margin-top: .5em;
    .action{
      padding: 0 .5em;
      margin: .5em;
      text-align: center;
      &.selected {
        animation: flash .5s infinite alternate-reverse linear;
      }
    }
  }

  .guide-bar {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    width: 100%;
    height: 2.5em;
    filter: drop-shadow(5px 3px 5px rgba(0, 0, 0, 0.5));
    .guide {
      margin: 0 1.5em;
      span {
        margin-left: 0.3em;
      }
    }
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
};

@keyframes bottomSlide {
  from {
    transform: translatey(2em);
  }
  to {
    transform: translatey(0);
  }
}

@keyframes topSlide {
  from {
    transform: translatey(-2em);
  }
  to {
    transform: translatey(0);
  }
}

@keyframes flash {
  from {
    background-color: #FFF0;
  }
  to {
    background-color: #FFF5;
  }
}



</style>

