<template>
  <div
    class="background"
    :style="
      'background:' + themes.find((t) => t.name == $root.currentTheme).style
    "
  >
    <div class="waves">
      <div class="wave1-cont">
        <svg
          id="wave1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="1354"
          height="361"
          viewBox="0 0 717.17 185.95"
          preserveAspectRatio="none"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#fff" stop-opacity="0.302" />
              <stop offset="0.695" stop-color="#fff" stop-opacity="0.039" />
              <stop offset="1" stop-color="#fff" stop-opacity="0.102" />
            </linearGradient>
          </defs>
          <path
            id="Tracciato_3"
            data-name="Tracciato 3"
            d="M717.17,0C589.05,0,494.46,88.22,344.32,88.22,179.17,88.22,140.13,0,0,0V186H717.17Z"
            fill="url(#linear-gradient)"
          />
        </svg>
        <svg
          id="wave1"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="1354"
          height="361"
          viewBox="0 0 717.17 185.95"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#fff" stop-opacity="0.302" />
              <stop offset="0.695" stop-color="#fff" stop-opacity="0.039" />
              <stop offset="1" stop-color="#fff" stop-opacity="0.102" />
            </linearGradient>
          </defs>
          <path
            id="Tracciato_3"
            data-name="Tracciato 3"
            d="M717.17,0C589.05,0,494.46,88.22,344.32,88.22,179.17,88.22,140.13,0,0,0V186H717.17Z"
            fill="url(#linear-gradient)"
          />
        </svg>
      </div>
      <div class="wave2-cont">
        <svg
          id="wave2"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="1278"
          height="362"
          viewBox="0 0 716 186.8"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#fff" stop-opacity="0.2" />
              <stop offset="0.695" stop-color="#fff" stop-opacity="0" />
              <stop offset="1" stop-color="#fff" stop-opacity="0.102" />
            </linearGradient>
          </defs>
          <path
            id="Tracciato_4"
            data-name="Tracciato 4"
            d="M716,0C588,0,494,88.14,344,88.14,179,88.14,140,0,0,0V185.8H716.5Z"
            fill="url(#linear-gradient)"
          />
        </svg>
        <svg
          id="wave2"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="1278"
          height="362"
          viewBox="0 0 716 186.8"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#fff" stop-opacity="0.2" />
              <stop offset="0.695" stop-color="#fff" stop-opacity="0" />
              <stop offset="1" stop-color="#fff" stop-opacity="0.102" />
            </linearGradient>
          </defs>
          <path
            id="Tracciato_4"
            data-name="Tracciato 4"
            d="M716,0C588,0,494,88.14,344,88.14,179,88.14,140,0,0,0V185.8H716.5Z"
            fill="url(#linear-gradient)"
          />
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
// import SineWaves from 'sine-waves';
export default {
  data() {
    return {
      themes: require("../config.json").themes,
      waves: null,
    };
  },

  /*
  mounted() {
    this.waves = new SineWaves({
    el: this.$refs.wave,
    speed: 10,
    width: function() {
      return window.innerWidth;
    },
    height: function() {
      return window.innerHeight;
    },
    wavesWidth: '100%',
    ease: 'linear',


    waves: [{
      timeModifier: 1,
      lineWidth: 1,
      amplitude: 100,
      wavelength: 350,
      segmentLength: 10,
      type: 'Sine',
      fillStyle: function(el, bound) {
        var gradient = this.ctx.createLinearGradient(bound.x0, bound.y0, bound.x1, bound.y1);
        gradient.addColorStop(0, 'rgba(255, 255, 255, 0.5)');
        gradient.addColorStop(0.5, 'rgba(255, 255, 255, 0)');
        return gradient;
      },
      strokeStyle: '',
      yAxis: function() {
        return this.height * 0.45;
      }
    },],
    initialize: function() {},
    resizeEvent: function() {}
  });




  },*/
};
</script>

<style lang="scss" scoped>
.background {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  /*background: radial-gradient(
    ellipse farthest-corner at 0% 0%,
    #000a66 0%,
    #4ff6fe 80%
  );*/
  background: linear-gradient(135deg, #00044e, #07b0de);
  pointer-events: none;
  z-index: -1;

  .waves {
    transition: 0.5s;
    height: 100%;
    width: 100%;
    transform-origin: bottom center;

    & > * {
      width: 100%;
      position: absolute;
      white-space: nowrap;
      bottom: -4px;
      left: 0;
      animation: 20s welcome-scroll linear infinite;
      animation-delay: 0.95;
    }

    #wave1 {
      width: 134.2vw;
      height: 57vh;
      //height: 50vh;
      //fill: rgba(255, 255, 255, 0.2);
    }

    #wave2 {
      width: 140vw;
      height: 50vh;
    }

    .wave2-cont {
      animation: 19.95s welcome-scroll2 linear infinite;
      // animation-delay: 1.35;
    }

    #title-text {
      opacity: 0;
      // animation: fade 1s forwards;
      animation-delay: 0.6s;
    }
  }


}

.boot .waves {
      & > * {
        animation: 1s welcome-scroll, 20s welcome-scroll linear infinite;
        animation-delay: calc(0s + 3.2s), calc(0.95s + 3.2s);
      }
      .wave2-cont {
        animation: 0.95s welcome-scroll2, 19.95s welcome-scroll2 linear infinite;
        animation-delay: calc(0s + 3.2s), calc(0.9s + 3.2s);
      }

  }

@keyframes welcome-scroll {
  0% {
    transform: translateX(-134.2vw);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes welcome-scroll2 {
  0% {
    transform: translateX(-140vw);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>

<style lang="scss">
.fade-leave-to {
  .waves {
    transform: scale(4);
  }
}
</style>
