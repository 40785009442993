import Vue from 'vue'
import Home from './views/Home.vue'
import GameBoot from './views/GameBoot.vue'
import './styles/main.scss';
import VueRouter from 'vue-router'
import App from './App.vue'
import VueGamepad from 'vue-gamepad';
import Dialog from './views/Dialog.vue';

Vue.use(VueGamepad);

Vue.config.productionTip = false
Vue.use(VueRouter)

const routes = [
  { path: '/', component: Home, query: {boot: 'true'} },
  { path: '/game', component: GameBoot, meta: {hideBg: true}},
  { path: '/dialog', component: Dialog}
]

const router =  new VueRouter({ routes });

const menu = require('./components/menu.js');

const navState = {
  listMenu: null,
  currentMenu: 4,
  menus: {
    4:1
  }
}

for(let i = 0; i < menu.length; i++) {
  if(!navState.menus[i]) navState.menus[i] = 0;
}

new Vue({
  router,
  data () {
    return {
      sidebar: null,
      gamepad: null,
      rebooting: false,
      boot: false,
      currentTheme: localStorage.currentTheme || 'blue',
      dialog: null,
      sounds: {
        nav_forward: require("./assets/snd/nav-forward.mp3"),
        nav_backward: require("./assets/snd/nav-backward.mp3"),
        gameboot: require("./assets/snd/gameboot.mp3"),
        alert: require("./assets/snd/error.mp3"),
        input: require("./assets/snd/input.mp3"),
        boot: require('./assets/snd/snd.mp3'),
      },
      keys: {
        key_back: 8,
        key_enter: 13,
        key_left: 37,
        key_up: 38,
        key_right: 39,
        key_down: 40
      },
      menu: menu,
      navState: navState,

    }
  },
  render: h => h(App),

  created() {
    if(!this.$route.query.boot) this.$router.push({query:{boot:true}})
  },

  methods: {
    playSound(file) {
      if(this.sounds[file]) {
        let a = new Audio(this.sounds[file]);
        a.play();
      } else console.error('No sound file for ' + file)
    },
  },

}).$mount('#app');

