<template>
  <div class="status-bar">
    {{ dateString }}

    <template v-if="battery">
      <img class="battery" :class="{charging: battery.charging, zero: battery.level <= 0.1, low: battery.level > 0.1 && battery.level < 0.40, mid: battery.level >= 0.40 && battery.level < 0.75, high: battery.level >= 0.75 && battery.level <= 0.99, full: battery.level == 1}">
    </template>
  </div>
</template>

<script>
import { format } from 'date-fns'

export default {
  data() {
    return {
      date: new Date(),
      battery: navigator.getBattery(),
      lang: window.navigator.language
    };
  },

  computed: {
    dateString() {
      return format(this.date, 'dd/MM HH:mm')
    }
  },

  mounted() {
    setInterval(() => {
      this.date = new Date();
    }, 1000);


    navigator.getBattery().then((battery) => {
      this.battery = {
        charging: battery.charging,
        level: battery.level
      }
      battery.addEventListener("chargingchange", () => {
        this.battery = {
          charging: battery.charging,
          level: battery.level
        }
      });

      battery.addEventListener("levelchange", () => {
        this.battery = {
          charging: battery.charging,
          level: battery.level
        }
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.status-bar {
  position: fixed;
  z-index: 99;
  top:0;
  right: 0;
  width: 100%;
  padding: .5em;
  color: white;
  -ms-overflow-style: scrollbar;
    -webkit-app-region:drag;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.5));
  text-align: right;
  .battery {
    height: 1em;
    vertical-align: baseline;
    margin-left: .5em;
    margin-bottom: -2px;

    &.zero {
      content: url('../assets/battery-zero.svg')
    }

    &.low {
      content: url('../assets/battery-low.svg')
    }

    &.mid {
      content: url('../assets/battery-mid.svg')
    }

    &.high, &.full {
      content: url('../assets/battery-high.svg')
    }

    &.charging {

      &.zero {
        animation: 2s linear zeroCharging infinite;
      }

      &.low {
        animation: 1.5s linear lowCharging infinite;
      }

      &.mid, &.high {
        animation: 1s linear midCharging infinite;
      }

    }
  }
}

@keyframes zeroCharging {

  0% {
    content: url('../assets/battery-zero.svg')
  }

  25% {
    content: url('../assets/battery-low.svg')
  }

  50% {
    content: url('../assets/battery-mid.svg')
  }

  75% {
    content: url('../assets/battery-high.svg')
  }

}

@keyframes lowCharging {

  0% {
    content: url('../assets/battery-low.svg')
  }

  33% {
    content: url('../assets/battery-mid.svg')
  }

  66% {
    content: url('../assets/battery-high.svg')
  }

}

@keyframes midCharging {

  0% {
    content: url('../assets/battery-mid.svg')
  }

  100% {
    content: url('../assets/battery-high.svg')
  }

}


</style>
