module.exports = [
  {
    title: "Impostazioni",
    icon: require('../assets/menu/settings.png'),
    subs: [
      {
        title: "Impostazioni video",
        icon: require('../assets/menu/movie.png'),
        altIcon: require('../assets/menu/settings/settingscircle.png'),
      },
      {
        title: "Impostazioni delle foto",
        icon: require('../assets/menu/photo.png'),
        altIcon: require('../assets/menu/settings/settingscircle.png'),
        subs: [
          {
            title: "Temi",
            icon: require('../assets/menu/settings/settingscircle.png'),
            sidebar: require('../config.json').themes.map(function(t) {
              return {
                title: t.name,
                showThemePreview: true,
                func: (root) => {
                  root.currentTheme = t.name
                  localStorage.currentTheme = t.name
                }
              }
            }),
          },
          {
            title: "Game",
            icon: require('../assets/menu/settings/settingscircle.png'),
            disableNavSound: true,
            url: '/game'
          },
          {
            title: "Full Screen Dialog",
            icon: require('../assets/menu/settings/settingscircle.png'),
            url: '/dialog'
          },
          {
            title: "Alert",
            icon: require('../assets/menu/settings/settingscircle.png'),
            func: (root) => {
              root.dialog = {
                content: "Errore 0X10C: Sei gay",
                type: 'alert',

                guide: {

                  cross: {
                    label: "Indietro",
                    func: (root) => {
                      root.dialog = null
                    }
                  },
                }
              }
            }
          },
          {
            title: "Input",
            icon: require('../assets/menu/settings/settingscircle.png'),
            func: (root) => {
              root.dialog = {
                content: "Vuoi scopare?",
                type: 'input',
                actions: [
                  {
                    label: "No",
                    func: (root) => {
                      root.dialog = null
                    }
                  },
                  {
                    label: "Sì",
                    func: (root) => {
                      window.open('https://pornhub.com')
                      root.dialog = null
                    }
                  }
                ],
                guide: {
                  cross: {
                    label: "Conferma"
                  },
                  circle: {
                    label: "Indietro",
                    func: (root) => {
                      root.dialog = null
                    }
                  },
                }
              }
            }
          },
        ]
      },
      {
        title: "Impostazioni del sistema",
        icon: require('../assets/menu/psp.png'),
        altIcon: require('../assets/menu/settings/settingscircle.png'),
        subs: [
          {
            title: "Riavvia il sistema",
            icon: require('../assets/menu/settings/settingscircle.png'),
            func: (root) => {
              root.dialog = {
                content: "Vuoi riavviare il sistema?",
                type: 'input',
                actions: [
                  {
                    label: "No",
                    func: (root) => {
                      root.dialog = null
                    }
                  },
                  {
                    label: "Sì",
                    func: (root, router) => {
                      root.rebooting=true;
                      setTimeout(() => {
                        router.go().then(() => {
                          root.rebooting = false
                        });

                      }, 700)
                    }
                  }
                ],
                guide: {
                  cross: {
                    label: "Conferma"
                  },
                  circle: {
                    label: "Indietro",
                    func: (root) => {
                      root.dialog = null
                    }
                  },
                }
              }
            }

          }
        ]
      },
    ],
  },
  {
    title: "Foto",
    icon: require('../assets/menu/photo.png'),
    subs: [
      {
        title: "ayy",
        icon: require('../assets/menu/folder.png'),
      },
      {
        title: "ayy",
        icon: require('../assets/menu/folder.png'),
        subs: [
          {
            title: "Ayyyyy",
            icon: require('../assets/menu/settings/settingscircle.png'),
          },
          {
            title: "Ayyyyy",
            icon: require('../assets/menu/settings/settingscircle.png'),
          },
          {
            title: "Ayyyyy",
            icon: require('../assets/menu/settings/settingscircle.png'),
          },
          {
            title: "Ayyyyy",
            icon: require('../assets/menu/settings/settingscircle.png'),
          },
          {
            title: "Ayyyyy",
            icon: require('../assets/menu/settings/settingscircle.png'),
          },
          {
            title: "Ayyyyy",
            icon: require('../assets/menu/settings/settingscircle.png'),
          },
          {
            title: "Ayyyyy",
            icon: require('../assets/menu/settings/settingscircle.png'),
          },
          {
            title: "Ayyyyy",
            icon: require('../assets/menu/settings/settingscircle.png'),
          },
          {
            title: "Ayyyyy",
            icon: require('../assets/menu/settings/settingscircle.png'),
          },
          {
            title: "Ayyyyy",
            icon: require('../assets/menu/settings/settingscircle.png'),
          },
          {
            title: "Ayyyyy",
            icon: require('../assets/menu/settings/settingscircle.png'),
          },
          {
            title: "Ayyyyy",
            icon: require('../assets/menu/settings/settingscircle.png'),
          },
        ]
      },
      {
        title: "ayy",
        icon: require('../assets/menu/folder.png'),
      },
    ],
  },
  {
    title: "Musica",
    icon: require('../assets/menu/music.png'),
    subs: [
      {
        title: "ayy",
        icon: require('../assets/menu/folder.png'),
      },
      {
        title: "ayy",
        icon: require('../assets/menu/folder.png'),
      },
      {
        title: "ayy",
        icon: require('../assets/menu/folder.png'),
      },
    ],
  },
  {
    title: "Video",
    icon: require('../assets/menu/movie.png'),
    subs: [
      {
        title: "ayy",
        icon: require('../assets/menu/folder.png'),
      },
      {
        title: "ayy",
        icon: require('../assets/menu/folder.png'),
      },
      {
        title: "ayy",
        icon: require('../assets/menu/folder.png'),
      },
    ],
  },
  {
    title: "Giochi",
    icon: require('../assets/menu/game.png'),
    subs: [
      {
        title: "ayy",
        icon: require('../assets/menu/folder.png'),
      },
      {
        title: "ayy",
        icon: require('../assets/menu/folder.png'),
      },
      {
        title: "ayy",
        icon: require('../assets/menu/folder.png'),
      },
    ],
  },
  {
    title: "Internet",
    icon: require('../assets/menu/network.png'),
    subs: [
      {
        title: "ayy",
        icon: require('../assets/menu/folder.png'),
      },
      {
        title: "ayy",
        icon: require('../assets/menu/folder.png'),
      },
      {
        title: "ayy",
        icon: require('../assets/menu/folder.png'),
      },
    ],
  },


]
