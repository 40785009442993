<template>
  <main
    :class="{
      boot: $route.query.boot,
      rebooting: $root.rebooting,
      sidebarOpened: $root.sidebar,
      dialogOpened: $root.dialog,
    }"
  >
    <transition name="fade">
      <XMBWave v-if="!$route.meta.hideBg" />
    </transition>
    <transition name="fade">
      <router-view />
    </transition>
    <transition name="fade">
      <Dialog v-if="$root.dialog" :dialog="$root.dialog" />
    </transition>
    <StatusBar/>

    <welcome-anim v-if="$route.query.boot" />

    <div id="reboot-overlay"></div>

  </main>
</template>

<script>
import WelcomeAnim from "./components/WelcomeAnim.vue";
import XMBWave from "./components/XMBWave";
import Dialog from "./components/Dialog";
import StatusBar from './components/StatusBar';
export default {
  components: {
    XMBWave,
    WelcomeAnim,
    Dialog,
    StatusBar
  },

  watch: {
    $route(to, from) {
    if (this.$route.query.boot && (to.path === '/' && from.path !== '/')) {
      this.$router.replace({ boot: null });
    }
  },
  }

};
</script>

<style lang="scss">

#reboot-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 99999;
  left: 0;
  visibility: hidden;
  opacity: 0;
  background-color: black;
  transition: .7s;
}

main {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  &.rebooting {
    #reboot-overlay {
    opacity: 1;
    visibility: visible;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
