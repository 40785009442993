<template>
  <div class="sidebar"

    v-gamepad:button-b="back"
    v-gamepad:button-a="enter"


    v-gamepad:button-dpad-up.repeat="up"
    v-gamepad:button-dpad-down.repeat="down"
    v-gamepad:left-analog-up.repeat="up"
    v-gamepad:left-analog-down.repeat="down"


  >

    <!-- Key Events -->
    <Keypress key-event="keydown" :key-code="$root.keys.key_up" @success="up" />
    <Keypress key-event="keydown" :key-code="$root.keys.key_down" @success="down" />
    <Keypress key-event="keydown" :key-code="$root.keys.key_back" @success="back" />
    <Keypress key-event="keydown" :key-code="$root.keys.key_enter" v-if="sidebar[currentIndex].func" @success="enter" />

    <div class="side-menu-container">
      <div
        id="side-menu-indicator"
        :style="`transform:translateY(${28 * currentIndex}px)`"
      ></div>
      <div v-for="(item, i) in sidebar" :key="i" class="sidebar-item">
        <div id="theme_pw" v-if="sidebar[currentIndex].showThemePreview" :style="'background:'+themes.find(t => t.name === item.title).style"/>
        {{ item.title }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["sidebar"],

  components: {
    Keypress: () => import('vue-keypress')
  },



  data() {
    return {
      currentIndex: 0,
      themes: require('../config.json').themes
    };
  },

  methods: {

    enter() {
      if(this.sidebar[this.currentIndex].func) this.sidebar[this.currentIndex].func(this.$root)
    },

    back() {
      this.$root.sidebar = null;
      this.$root.playSound("nav_backward");
    },

    up() {
      if (this.currentIndex == 0) return;
      this.currentIndex--;
      this.$root.playSound("nav_forward");
    },

    down() {
      if (this.currentIndex == this.sidebar.length - 1) return;
      this.currentIndex++;
      this.$root.playSound("nav_forward");
    }

  },


};
</script>

<style lang="scss" scoped>
.sidebar {
  height: 100vh;
  position: relative;
  position: fixed;
  top: 0;
  right: 0;
  width: 25vw;
  min-width: 250px;
  max-width: 400px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.2) 35%
  );

  .side-menu-container {
    width: 100%;
    position: absolute;
    top: 42%;
    .sidebar-item {
      display: flex;
      align-items: center;
      #theme_pw {
        width: 1em;
        height: 1em;
        margin-right: .5em;
      }
      box-sizing: border-box;
      padding: 0.25em 1em;
      position: relative;
      z-index: 1;
      color: white;
      text-shadow: 1px 2px 5px rgba(0,0,0,.8)
    }
  }

  #side-menu-indicator {
    height: 28px;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.3) 25%,
      rgba(255, 255, 255, 0.3) 75%,
      rgba(255, 255, 255, 0.8)
    );
    animation: fade .6s linear alternate-reverse infinite;
    transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    z-index: 0;
  }
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
