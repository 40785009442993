<template>

  <div class="gameboot-screen">

    <Keypress key-event="keydown" :key-code="$root.keys.key_enter" @success="$router.go(-1)" />


    <div class="center-row">
      <img src="../assets/psp-logo.svg" fill="white">
    </div>

    <div class="anim-container">
      <div class="circles">
        <div id="circle1"></div>
        <div id="circle2"></div>
      </div>
    </div>

  </div>

</template>

<script>
import Keypress from 'vue-keypress';
export default {

  components: {
    Keypress
  },

  mounted () {
    this.$root.playSound('gameboot')
  }

}
</script>

<style lang="scss" scoped>


.gameboot-screen {
  position: fixed;
  z-index: 999;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  background-color: white;
  font-size: 16px;
  pointer-events: none;
  animation-delay: 3.3s;
  display: grid;
  place-items:center;
}

.center-row {
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
  img {
    height: 100px;
  }
  opacity: 0;
  animation: fade forwards 1s;
}




.anim-container {
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: bottom right;
  animation: fadeInOut .8s forwards;


#circle1 {
   width:80vh;
   height: 80vh;
   background-image: linear-gradient(-45deg, #43cbfd 0%, #65e3ff 50%);
   position: absolute;
   top:30%;
   left:0%;
   border-radius: 50%;
   opacity: 0;
   animation: scale1 .8s forwards;
   animation-delay: .1s;
   transform-origin: bottom right;
   // filter: blur(1px);
 }

 #circle2 {
   width:50vh;
   height: 40vh;
   background-image: radial-gradient(circle at top left, #63b320cc, rgba(255, 229, 61, 0.9) 50%);
   position: absolute;
   bottom:0%;
   right: -20%;
   border-radius: 50%;
   animation: scale2 .8s forwards;
   transform-origin: center right;
   //box-shadow: inset 30px 30px 200px rgba(255,255,255,.5);
 }

}



@keyframes welcome-scroll {
  0% {
    transform: translateX(-100vw);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scale1 {
  0% {
    transform: scale(.5);
    opacity: 0;
  }

  100% {
    transform: scale(4);
    opacity: 1;
  }

}

@keyframes scale2 {
  0% {
    transform: scale(.5);
    opacity: 0;
  }

  100% {
    transform: scalex(5.5) scaley(7.5);
    opacity: 1;
  }

}


@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


</style>
